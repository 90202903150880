.art {
  font-family: 'Old Standard TT', Serif;
  font-size: 40px;
  margin-left: .5em;
  
}

.cartbtn {
  justify-content: flex-end;
}

.navLink{
  margin: 0px 1em;
  color: black;
  font-size: 18px;
  font-family: 'Rubik', sans-serif;
  transition: 300ms;
}
.navLink:hover{
  color:#d69d66 !important;
}



#cartbox {
  background-color: #d69d66;
  border-radius: 0;
  color: #fff;
  text-align: center;
  transition: all 0.5s;
  cursor: pointer;
  box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.7);
  padding: .5em 1.5em;
}

#cartbox {
  cursor: pointer;
  position: relative;
  transition: 0.5s;
}

#cartbox:after {
  content: '»';
  position: absolute;
  opacity: 0;

  right: -20px;
  transition: 0.5s;
}

#cartbox:hover {
  padding-right: 20px;
  padding-left: 15px;
  background-color: #3d6d79;
}

#cartbox:hover:after {
  opacity: 1;
  right: 10px;
}


@media only screen and (max-width: 1024px) {
  #cartbox{
    margin-left: 1rem;
    
  }
}


@media only screen and (max-width: 500px) {
  .art{
    font-size: 25px;
  }
}