.viewItemContainer {
  text-align: center;
}

.imagecontainer {
  width: 100%;

  height: fit-content;

  background-color: #3d6d79;
  padding: 8rem 0 2rem;
}

.image {
  height: 600px;
  border: 3px solid black;
  box-shadow: 8px 5px 5px black;
  border-radius: 3px;
}

.viewText {
  margin-top: 2rem;
}

.btn {
  font-size: 18px;
  background-color: #d69d66;
  color: white;
  text-shadow: 1px 1px grey;
  width: 125px;
  margin: 2px 6px;
  padding: 10px;
  transition: all 0.3s ease-in-out;
}
.btn:hover {
  background-color: #3d6d79;
}

@media only screen and (max-width: 1250px) {
  .image {
    height: 500px;
  }
}
@media only screen and (max-width: 1075px) {
  .image {
    height: 400px;
  }
}
@media only screen and (max-width: 875px) {
  .image {
    height: 300px;
  }
}

@media only screen and (max-width: 768px) {
  
  .btn{
    transition: none;
  }
  .btn:hover {
    background-color:#d69d66;
    transform: none;
  }
  .btn:active {
    background-color:#3d6d79;
  }
  
}

@media only screen and (max-width: 640px) {
  .image {
    height: 200px;
  }
}

@media only screen and (max-width: 500px) {
  .image {
    width: 90%;

    height: auto;
    object-fit: cover;
    /* think it was this */
    box-shadow: none;
  }
}
@media only screen and (max-width: 450px) {
  .buttons {
    flex-direction: column;
    align-items: center;
  }
  .btn {
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding: 5px;
    width: 200px;
  }
}
