.footerContainer{
    margin-top: 6rem;
    border-top: 2px solid black;
    background-color: #d69d66;
    text-align: center;
    padding: .6rem 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.title{
    width: 63%;
    border-bottom: 1px solid black;
    padding-bottom: 7px;
    font-size: 22px;
    
}
.notice{
    font-size: 12px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 725px) {
    .icons{
        margin: 2px;
    }
    .footerContainer{
        padding-bottom: .5rem;
    }
  }
  @media only screen and (max-width: 500px) {
    .footerContainer{
        margin-top: 1.5rem;
    }
  }

