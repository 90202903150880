/* Cart */

.checkoutBox {
  margin-top: 7rem;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.checkoutContainer {
  /* height: 350px; */
  height: fit-content;
  width: 275px;
  /* background-color: #f0f0f0; */
  margin: 3.8rem 3rem 2.5rem;
  border-top: 3px solid #d69d66;
  border-left: 3px solid #d69d66;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.cartSummaryText {
  padding: 1rem 0;
  border-bottom: 2px solid #e6e6e6;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.priceRemove {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
}
.EditCart {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: fit-content;
  padding-bottom: 3rem;
  border-bottom: 1px solid black;
}
.priceAdjustment {
  margin: 0;
  margin-left: 7rem;
}
.itemsinCheckoutCart {
  display: flex;
  justify-content: space-between;
  margin-left: 1rem;
}

.cartActionAndPrice {
  margin-left: 1rem;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 2px solid #e6e6e6;
}

.stripeText {
  margin-left: 3.5rem;
  color: orange;
  font-size: 11px;
}

.cartItemContainer {
  height: 125px;
  width: 100%;
  background-color: #e6e6e6;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 2px;
}
/* cart */
.itemImage {
  height: 100px;
  border-radius: 2px;
}

.description {
  font-size: 12px;
}
.title {
  font-size: 12px;
}
.nameDesc {
  margin-left: 5px;
}
.removeItem {
  margin-right: 2rem;
}
.removeItem:hover {
  cursor: pointer;
}
.imageText {
  display: flex;
  width: 20.18rem;
}
.priceInItem{
  margin-right: 2.6rem;
}
.priceItemUnderDes{
  display: none;
}
.removeItemUnderDes{
  display: none;
}
/* CartItem */
/* Cart Action */
.Checkoutbtn {
  width: 55%;
  background-color: #d69d66;
  border-radius: 5px;
  color: white;
  transition: 400ms;
}
.Checkoutbtn:hover {
  background-color: #3d6d79;
}
/* Cart Action */


/* media queries */
@media only screen and (max-width: 1200px) {
  .EditCart {
    width: 31rem;
  }
  .checkoutContainer {
    width: 250px;
    margin-left: 2.4rem;
  }
  
}
@media only screen and (max-width: 855px) {
  .checkoutBox{
    flex-direction: column-reverse;
    align-items: center;
  }
  .EditCart{
    width: 90%;
  }
  .checkoutContainer{
    border: 2px solid #d69d66;
    width: 22rem;
    padding: 1rem;
    margin-top: 3.5rem;
  }
  .stripeText{
    margin-left: 4.5rem;
    margin-top: 0rem;
  }
}

@media only screen and (max-width: 600px) {
  .description{
    font-size: 11px;
  }
  .title{
    font-size: 11px;
  }
  .EditCart{
    width: 95%;
  }
  .description{
    width: 8rem;
  }
}
@media only screen and (max-width: 530px) {
  .EditCart{
    width: 98%;
  }
  .itemImage {
    height: 75px;
  }
  .priceAdjustment{
    margin-left: 3rem;
  }
  .priceInItem{
    position: relative;
    right: 50px;
    margin: 0;
  }
  
  .checkoutContainer{
    margin: 3.3rem auto 0 ;
    width: 90%;
  }
  .textUnderCart{
    width: 100%;
  }
  .stripeText{
    text-align: center;
    margin: 1rem;
  }
  
}

@media only screen and (max-width: 450px) {
  
  .priceAdjustment{
    display: none;
  }
  .priceItemUnderDes{
    display: inline;
    font-size: 11px;
    font-weight: bold;
  }
  .priceInItem{
    display: none;
  }
  .removeText{
    display: none;
  }
  .description{
    margin: 0;
  }
  .imageText{
    margin-top: 4px;
  }

}
@media only screen and (max-width: 355px) {
  .removeItem{
    display: none;
  }
  .removeItemUnderDes{
    display: inline;
  }
  .cartItemContainer{
    width: 100%;
  }
  .imageText{
    justify-content: space-evenly;
  }

}

/* spinner */

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}