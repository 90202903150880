.aboutContainer{
    height: 100vh;
    /* margin-top: 7rem; */
    display: flex;
    justify-content:center;
    align-items: center;
}

.myImage{
    height: 320px;
    width: 305px;
    border-radius: 50%;
    border: 8px solid #3d6d79;
    margin-right: 2rem;
}

.text{
    width: 30rem;
    margin-left: 2rem;
    margin-top: 3.5rem;
    font-family: 'Rubik', sans-serif;
}

@media only screen and (max-width: 890px) {
    
.aboutContainer{
    flex-direction: column;
    margin-top: 5rem;
    height: max-content
}
.myImage{
    margin-top: 3rem;
    margin-right: 0;
}
.text{
    margin-left: 0;
}
}

@media only screen and (max-width: 550px) {
    .text{
        width: 85%;
    }
}

@media only screen and (max-width: 375px) {
    .myImage{
        width: 85%;
        height: fit-content;
    }
}