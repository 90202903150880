.parentContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 7rem;
}

.buyDirectly {
  text-align: center;
  font-family: 'Old Standard TT', Serif;
  font-size: 46px;
  margin-top: 8rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.productContainer {
  width: 25rem;
  border-radius: 3px;
  display: flex;
  margin: 4rem 2.5rem;
  transition: all 0.2s ease-in-out;
}

.actualproductContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid black;
}

.productContainer:hover {
  transform: scale(1.05);
}

.imagebox {
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 40px auto 30px;
  height: 57%;
  /* height: 24rem; */
  /* width: 90%; */
  background-color: #3d6d79;
  padding: 32px 0;
  justify-items: center;
}

.productImage {
  width: 72%;
  border-radius: 3px;
  /* height:fit-content; */
  border: 2px solid black;
  box-shadow: 10px 5px 5px black;
}

.productTitle {
  font-size: 22px;
  font-family: 'Rubik', sans-serif;
  margin-bottom: 10px;
  text-align: center;
}

.btn {
  background-color: #d69d66;
  color: white;
  text-shadow: 1px 1px grey;
  width: 125px;
  margin-bottom: 14px;
  transition: all 0.2s ease-in-out;
}

.btn:hover {
  background-color: #3d6d79;
  transform: scale(1.05);
}

@media only screen and (max-width: 1575px) {
  .parentContainer{
    justify-content: space-evenly;
  }
  
  .productContainer{
    width: 22rem;
    margin: 2rem 2rem;
  }
}

@media only screen and (max-width: 1254px) {
  .parentContainer{
    justify-content: center;
  }
}

@media only screen and (max-width: 850px) {
  .productContainer{
    width: 20rem;
    margin: 1.25rem 1.25rem;
  }
 
}

@media only screen and (max-width: 768px) {
  
  .productContainer:hover {
    transform: none;
  }
  .btn{
    transition: none;
  }
  .btn:hover {
    background-color:#d69d66;
    transform: none;
  }
  .btn:active {
    background-color:#3d6d79;
  }
  
}

@media only screen and (max-width: 720px) {
  .productContainer{
    width: 24rem;
    margin: 1.25rem 1.25rem;
  }
  .parentContainer{
    margin-top: 3rem;
  }
  
}

@media only screen and (max-width: 500px) {
  .productContainer{
    width: 85%;
    margin: 1.25rem 1.25rem;
  }
  .buyDirectly{
    margin-top: 0;
  }
  
}

@media only screen and (max-width: 425px) {
  .productContainer{
    width: 95%;
    margin: 1.25rem 1.25rem;
  }
  /* .btn{
    width: 125px;
    padding: 1px;
  } */
  
  
}