

.box{
    
    width: 100%;
    background-image: url('../../images/bear.jpg');
    background-size: cover;
    height: 45rem;
    margin-top: 5rem;
    background-repeat: no-repeat;
    display: flex;
    background-position: 25% 75%;
}

.text{
    
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.textBackground{
    font-size: 64px;
    padding: 4px;
    font-family: 'Old Standard TT', Serif;
}

.textBackgroundsm{
    font-size: 24px;
    padding: 2px;
    font-family: 'Old Standard TT', Serif;
}

@media only screen and (max-width: 1600px) {
    .box{
        height: 40rem;
    }
  }

@media only screen and (max-width: 1200px) {
    .box{
        background-position: 0% 75%;
    }
  }

  @media only screen and (max-width: 1000px) {
    .box{
        height: 30rem;
    }
  }
  @media only screen and (max-width: 660px) {
    .box{
        height: 25rem;
    }
    .textBackground{
        font-size: 44px;
    }
  }

  @media only screen and (max-width: 500px) {
    .box{
        height: 22.5rem;
    }
    .textBackground{
        font-size: 36px;
        text-align: center;
    }
  }

 